.slick-slide {
  padding: 0 8px;
}

.slick-list {
  margin: 0 -8px;
}

.dots li {
  width: 100%;
  margin: 0;
}

.dots li button {
  width: 100%;
  background-color: #B3DAE0;
  transition: 0.7s all;
}

.dots li button:hover {
  width: 100%;
  background-color: #B3DAE0;
  transition: 0.7s all;
}

.dots li button:focus {
  outline: 'none';
  background-color: #B3DAE0;
}

.dots li button::before {
  content: none;
}

.dots li.slick-active button {
  background-color: #66B5C1;
  transition: 0.7s all;
}

.dots li.slick-active button:hover {
  background-color: #66B5C1;
  transition: 0.7s all;
}
