p {
    /* text-indent: 50px; */
    margin-bottom: 10px;
    margin-top: 10px;
}

h1 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

h2 {
    margin-bottom: 10px;
    margin-top: 10px;
}
